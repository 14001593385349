<template>
    <div class="table__box--container">
        <div class="table-responsive">
            <table class="table mb-0 table-bordered">
                <thead>
                    <tr>
                        <th>{{ $t('tableBox.stt') }}</th>
                        <th>{{ $t('tableBox.nameCP') }}</th>
                        <th>{{ $t('tableBox.progressStatus') }}</th>
                        <th>{{ $t('tableBox.secretariatStartDate') }}</th>
                        <th>{{ $t('tableBox.campaignEndDate') }}</th>
                        <th>{{ $t('tableBox.secretariatEndDate') }}</th>
                        <th>{{ $t('tableBox.personInCharge') }}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody v-if="$props.listData.length > 0 && !$props.isLoading">
                    <template>
                        <tr v-for="(item, k) in $props.listData" :key="item.id">
                            <td>
                                {{ parseInt(($props.inforPage.page - 1) * $props.inforPage.limit) + parseInt(k + 1) }}
                            </td>
                            <td class="truncate-cell" style="width: 280px">
                                <p class="truncate-cell" style="width: 280px; white-space: nowrap">
                                    {{ item.name }}
                                </p>
                            </td>
                            <td>
                                {{ renderNameProgress(item.progress_status) }}
                            </td>
                            <td>{{ item.secretariat_start_date }}</td>
                            <td>{{ item.campaign_end_date }}</td>
                            <td>{{ item.secretariat_end_date }}</td>
                            <td class="truncate-cell" style="width: 250px">
                                <p class="truncate-cell" style="width: 250px; white-space: nowrap">
                                    {{ item.person_in_charge?.name }}
                                </p>
                            </td>
                            <td align="center">
                                <a href="javascript:void(0)" class="text-info" @click="goToDetail('campaign/form/view', item)">{{
                                    $t('btn.detail')
                                }}</a>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else-if="$props.isLoading">
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <LoadingIcon />
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <p class="mt-3 text-center">
                                    {{ $t('notificationCommon.noData') }}
                                </p>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <!-- <Pagination
      v-if="$props.inforPage.total"
      :total="$props.inforPage.total"
      :page.sync="$props.inforPage.page"
      :per-page="$props.inforPage.limit"
    /> -->
    </div>
</template>

<script>
// import Pagination from "@/components/Pagination/main.vue";
import { campaignStatus } from '@/config/var-common';
import LoadingIcon from '@/components/Loading/main.vue';
export default {
    components: {
        // Pagination,
        LoadingIcon
    },
    props: {
        listData: {
            type: Array,
            required: true
        },
        inforPage: {
            type: Object
        },
        isLoading: {
            type: Boolean,
            require: false,
            default: () => false
        }
    },
    data() {
        return {};
    },
    methods: {
        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        renderNameProgress(params) {
            return campaignStatus.find((item) => item.id === params)?.text;
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
