<script>
import { authComputed, authFackMethods, masterMethods, campaignMethods } from '@/state/helpers';
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import DashboardAdmin from './admin/main.vue';
import DashboardSaleAndInstructor from './sales/main.vue';
import DashboardEmployee from './employee-part-time/main.vue';
import DashboardPartner from './partner/main.vue';
import DashboardManagerNumberProject from './manager/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';

/**
 * Dashboard component
 */
export default {
    components: {
        Layout,
        PageHeader,
        DashboardAdmin,
        DashboardSaleAndInstructor,
        DashboardEmployee,
        DashboardPartner,
        DashboardManagerNumberProject,
        MultiSelectCommon
    },
    data() {
        return {
            items: [
                {
                    text: 'ダッシュボード',
                    active: true
                }
            ],
            diffTable: false,
            textButton: '案件数管理',
            textTitle: 'ダッシュボード',
            campaignObject: null,
            listDataMasterCampaign: [],
            configDefaultCampaign: {
                error: false,
                isRemove: true,
                trackBy: 'id',
                label: 'name',
                loading: false
            },
            isHandleRemoveBookmark: false,
            userInfo: null
        };
    },
    computed: {
        ...authComputed
    },
    methods: {
        ...authFackMethods,
        ...masterMethods,
        ...campaignMethods,

        setDefault() {
            if (this.configDefaultCampaign.loading) return;
            this.configDefaultCampaign.loading = true;
            let object = JSON.parse(JSON.stringify(this.campaignObject));
            object.campaign_id = object?.id ? object.id : '';
            this.setDefaultCampaign(object).then((data) => {
                if (data.code == 200) {
                    let token = this.$cookies.get('token');
                    if (token !== undefined) {
                        this.refreshtoken();
                    }
                }
                this.configDefaultCampaign.loading = false;
            });
        },

        getListMasterCampaign() {
            this.getAllDocuments().then((data) => {
                if (data.code == 200) {
                    this.listDataMasterCampaign = data.data;
                }
            });
        },

        handleChangeTable() {
            if (this.diffTable) {
                this.textButton = '案件数管理';
            } else {
                this.textButton = '戻る';
            }
            this.diffTable = !this.diffTable;
        },

        handleGetDetail() {
            this.$router.push({
                path: '/campaign/form/view',
                query: {
                    id: this.campaignObject.id
                }
            });
        },

        handleDocument() {
            this.$router.push({
                path: '/documents'
            });
        },

        handleGetDetailBookmark(obj) {
            this.$router.push({
                path: '/campaign/form/view',
                query: {
                    id: obj.id
                }
            });
        },

        async handleRemoveBookmark(obj) {
            if (this.isHandleRemoveBookmark) return;
            this.isHandleRemoveBookmark = true;
            try {
                this.userInfo.campaign_bookmarks = this.userInfo.campaign_bookmarks.filter((item) => item.id !== obj.id);
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.unsetBookmarkCampaign(obj.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.isHandleRemoveBookmark = false;
            }
        }
    },
    mounted() {
        this.campaignObject = {
            campaign_id: this.currentUser && this.currentUser.default_campaign ? this.currentUser.default_campaign.id : '',
            id: this.currentUser && this.currentUser.default_campaign ? this.currentUser.default_campaign.id : '',
            name: this.currentUser && this.currentUser.default_campaign ? this.currentUser.default_campaign.name : ''
        };
        this.userInfo = JSON.parse(localStorage.getItem('user'));
        this.getListMasterCampaign();
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="textTitle" :items="items" />
        <div class="row form__common--center">
            <div class="col-lg-12 dashboard__main">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-2">
                                <label for="" class="mb-0"> {{ $t('pageCampaign.defaultCampaign') }}</label>
                            </div>
                            <div class="col-sm-10 px-0 row align-items-center" style="row-gap: 10px">
                                <div class="col-sm-10 px-0">
                                    <MultiSelectCommon
                                        :id="`select-set-campaign-default`"
                                        :value.sync="campaignObject"
                                        :options="listDataMasterCampaign"
                                        :config="configDefaultCampaign"
                                        @select="setDefault()"
                                    />
                                </div>
                                <div class="col-sm-2 px-0 row" style="gap: 12px; min-width: fit-content">
                                    <button type="submit" @click="handleGetDetail" class="btn btn-sm btn-primary ml-4">
                                        {{ $t('btn.detail') }}
                                    </button>
                                    <button type="submit" @click="handleDocument" class="btn btn-sm btn-info">
                                        {{ $t('pageCampaign.document') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.userInfo?.campaign_bookmarks?.length > 0">
                            <div class="list--bookmark mt-4">
                                <div class="col-sm-12 row" style="gap: 12px; flex-wrap: nowrap; overflow: hidden">
                                    <div v-for="(bookmark, idx) in this.userInfo.campaign_bookmarks" :key="idx" style="min-width: 180px">
                                        <div class="d-flex" style="gap: 3px">
                                            <span>
                                                <i class="ri-bookmark-fill" style="color: #157efb"></i>
                                            </span>
                                            <div class="d-flex" data-toggle="tooltip" :title="bookmark.name">
                                                <p class="name__bookmark" style="width: 120px" @click="handleGetDetailBookmark(bookmark)">
                                                    {{ bookmark.name }}
                                                </p>
                                            </div>
                                            <span class="remove__bookmark" @click="handleRemoveBookmark(bookmark)">
                                                <i class="ri-close-fill"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="list--more">
                                    <p id="popover-button-variant" tabindex="0">
                                        <i class="fa fa-angle-double-right"></i>
                                    </p>
                                    <b-popover
                                        target="popover-button-variant"
                                        variant="null"
                                        placement="bottom"
                                        triggers="focus"
                                        custom-class="more--bookmark"
                                    >
                                        <template #title>{{ $t('pageDashboard.more') }}</template>
                                        <div class="box-list" :style="`height: ${this.userInfo?.campaign_bookmarks?.length > 10 ? '500px' : '100%'}`">
                                            <div
                                                v-for="(bookmark, idx) in this.userInfo.campaign_bookmarks"
                                                :key="idx"
                                                style="display: flex; flex-wrap: nowrap; gap: 5px"
                                            >
                                                <span>
                                                    <i class="ri-bookmark-fill" style="color: #157efb"></i>
                                                </span>
                                                <div class="d-flex" data-toggle="tooltip" :title="bookmark.name">
                                                    <p class="name__bookmark" @click="handleGetDetailBookmark(bookmark)">
                                                        {{ bookmark.name }}
                                                    </p>
                                                    <span class="remove__bookmark" @click="handleRemoveBookmark(bookmark)">
                                                        <i class="ri-close-fill"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </b-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="currentUser?.role?.id === 1">
                    <div class="text-right">
                        <p class="btn my-3" @click="handleChangeTable">
                            {{ textButton }}
                        </p>
                    </div>
                    <DashboardAdmin v-if="!diffTable" />
                    <DashboardManagerNumberProject v-else />
                </div>
                <DashboardSaleAndInstructor :screen="currentUser?.role?.id" v-if="currentUser?.role?.id === 2 || currentUser?.role?.id === 3" />
                <DashboardEmployee v-if="currentUser?.role?.id === 4" />
                <DashboardPartner v-if="currentUser?.role?.id === 5" />
            </div>
        </div>
    </Layout>
</template>
<style lang="scss" scoped>
.remove__bookmark {
    cursor: pointer;
    i {
        font-size: 15px;
        &:hover {
            backdrop-filter: contrast(0.5);
            color: #ca3d3d;
        }
    }
}
.list--bookmark {
    position: relative;
}

.list--more {
    user-select: none;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffff;
    padding: 0px 12px;
    p {
        cursor: pointer;
        &:hover {
            color: #101111;
            transition: all 0.3s ease-in-out;
        }
    }
}

.name__bookmark {
    font-size: 14px;
    white-space: nowrap;
    width: 215px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.more--bookmark .popover-body {
    .box-list {
        width: 260px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 12px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #c1c1c1dc;
        }
    }
}
</style>
