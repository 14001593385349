<template>
    <div>
        <div class="card">
            <!-- 2 sale -->
            <div v-if="$props.screen === 2">
                <!-- START CAMPAINGS_PROCESSING -->
                <div>
                    <div class="card-header">
                        <h3
                            v-if="userInfo.team_id"
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        sale_staff_team: userInfo.team_id,
                                        progress_status: sttEvent1.toString()
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 運用中 )
                        </h3>
                        <h3
                            v-else
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        progress_status: sttEvent1.toString()
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 運用中 )
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableBox :list-data="listDataCPProcessing" :infor-page="processing" :is-loading="isLoadingCPProcessing" />
                    </div>
                </div>
                <!-- END CAMPAINGS_PROCESSING -->

                <!-- START CAMPAINGS_BEFORE-THE-EVENT -->
                <div>
                    <div class="card-header">
                        <h3
                            v-if="userInfo.team_id"
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        sale_staff_team: userInfo.team_id,
                                        progress_status: sttEvent2.toString()
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 開催前 )
                        </h3>
                        <h3
                            v-else
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        progress_status: sttEvent2.toString()
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 開催前 )
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableBox :list-data="listDataCPBeforeTheEvent" :infor-page="beforeTheEvent" :is-loading="isLoadingCPBeforeTheEvent" />
                    </div>
                </div>
                <!-- END CAMPAINGS_BEFORE-THE-EVENT -->
            </div>

            <!-- 3  instructor -->
            <div v-if="$props.screen === 3">
                <div>
                    <div class="card-header">
                        <h3
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: { is_approved: 0 }
                                })
                            "
                        >
                            承認待ちキャンペーン
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableBox :list-data="listDataCampain" :infor-page="paginationCP" :is-loading="isLoadingDataCP" />
                    </div>
                </div>
                <!-- START CAMPAINGS_PROCESSING -->
                <div>
                    <div class="card-header">
                        <h3
                            v-if="userInfo.team_id"
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        team_id: userInfo.team_id,
                                        progress_status: sttEvent1.toString(),
                                        is_approved: 1
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 運用中 )
                        </h3>
                        <h3
                            v-else
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        progress_status: sttEvent1.toString(),
                                        is_approved: 1
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 運用中 )
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableBox :list-data="listDataCPProcessing" :infor-page="processing" :is-loading="isLoadingCPProcessing" />
                    </div>
                </div>
                <!-- END CAMPAINGS_PROCESSING -->

                <!-- START CAMPAINGS_BEFORE-THE-EVENT -->
                <div>
                    <div class="card-header">
                        <h3
                            v-if="userInfo.team_id"
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        team_id: userInfo.team_id,
                                        progress_status: sttEvent2.toString(),
                                        is_approved: 1
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 開催前 )
                        </h3>
                        <h3
                            v-else
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: {
                                        progress_status: sttEvent2.toString(),
                                        is_approved: 1
                                    }
                                })
                            "
                        >
                            キャンペーン一覧 ( 開催前 )
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableBox :list-data="listDataCPBeforeTheEvent" :infor-page="beforeTheEvent" :is-loading="isLoadingCPBeforeTheEvent" />
                    </div>
                </div>
                <!-- END CAMPAINGS_BEFORE-THE-EVENT -->
            </div>
        </div>
    </div>
</template>

<script>
import appConfig from '@/app.config';
import { masterMethods, campaignMethods } from '@/state/helpers';
import { state } from '@/state/modules/authfack';
import TableBox from '@/components/TableBox/main.vue';

export default {
    page: {
        title: '営業・ディレクション',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        TableBox
    },
    props: {
        screen: Number
    },
    data() {
        return {
            userInfo: state.user,
            sttEvent1: [3, 4],
            sttEvent2: [1, 2],
            paginationCP: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCampain: [],
            filterCP: {
                is_approved: ''
            },
            isLoadingDataCP: false,
            //  START CAMPAINGS_PROCESSING
            processing: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCPProcessing: [],
            filters: {
                progress_status: '',
                team: '',
                is_approved: ''
            },
            has: {
                sale_staff_team: ''
            },
            isLoadingCPProcessing: false,
            // END  CAMPAINGS_PROCESSING

            //  START CAMPAINGS_BEFORE-THE-EVENT
            beforeTheEvent: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCPBeforeTheEvent: [],
            isLoadingCPBeforeTheEvent: false
            // END  CAMPAINGS_BEFORE-THE-EVENT
        };
    },
    watch: {},
    mounted() {
        if (this.$props.screen === 2) {
            this.has.sale_staff_team = this.userInfo.team_id;
        } else {
            this.filters.team = this.userInfo.team_id;
            this.getListCPAwaitApproval();
        }
        this.filters.is_approved = 1;
        this.getListCPProcessing();
        this.getListCPBeforeTheEvent();
    },
    computed: {},
    methods: {
        ...masterMethods,
        ...campaignMethods,
        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString(typeQ) {
            let query;
            if (typeQ === 'processing') {
                query = [`page=${this.processing.page}`, `limit=${this.processing.limit}`, `sorts[id]=desc`];
            } else if (typeQ === 'before_the_event') {
                query = [`page=${this.beforeTheEvent.page}`, `limit=${this.beforeTheEvent.limit}`, `sorts[id]=desc`];
            }

            if (this.filters) {
                for (const [key, value] of Object.entries(this.filters)) {
                    if (key && value) {
                        query.push(`filters[${key}]=${value.toString()}`);
                    }
                }
            }
            if (this.has) {
                for (const [key, value] of Object.entries(this.has)) {
                    if (key && value) {
                        query.push(`has[${key}]=${value.toString()}`);
                    }
                }
            }

            return query.join('&');
        },

        getQueryStrinApproval() {
            let query;
            query = [`page=${this.paginationCP.page}`, `limit=${this.paginationCP.limit}`, `sorts[id]=desc`];

            if (this.filterCP) {
                for (const [key, value] of Object.entries(this.filterCP)) {
                    if (key && value) {
                        query.push(`filters[${key}]=${value.toString()}`);
                    }
                }
            }

            return query.join('&');
        },

        getListCPAwaitApproval() {
            this.isLoadingDataCP = true;
            this.filterCP.is_approved = '0';
            let query = this.getQueryStrinApproval();
            this.listCampaign(query).then((data) => {
                this.listDataCampain = data.data;
                this.paginationCP.total = data._metadata.total;
                this.paginationCP.page = data._metadata.page;
                this.paginationCP.limit = data._metadata.limit;
                this.isLoadingDataCP = false;
            });
        },

        getListCPProcessing() {
            this.isLoadingCPProcessing = true;
            this.filters.progress_status = this.sttEvent1;
            let query = this.getQueryString('processing');
            this.listCampaign(query).then((data) => {
                this.listDataCPProcessing = data.data;
                this.processing.total = data._metadata.total;
                this.processing.page = data._metadata.page;
                this.processing.limit = data._metadata.limit;
                this.isLoadingCPProcessing = false;
            });
        },
        getListCPBeforeTheEvent() {
            this.isLoadingCPBeforeTheEvent = true;
            this.filters.progress_status = this.sttEvent2;
            let query = this.getQueryString('before_the_event');
            this.listCampaign(query).then((data) => {
                this.listDataCPBeforeTheEvent = data.data;
                this.beforeTheEvent.total = data._metadata.total;
                this.beforeTheEvent.page = data._metadata.page;
                this.beforeTheEvent.limit = data._metadata.limit;
                this.isLoadingCPBeforeTheEvent = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
